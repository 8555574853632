<div class="container-fluid text-md-center" style="margin-bottom: 50px;">
    <div style="width: 100%;">
        <img class="d-block" style="width: 100%; max-height: 400px; object-fit: cover;" src="../../../assets/img/person-working.jpg" alt="Portafolio">
    </div>
    <div class="row" style="width: 100%; margin-top: 20px; margin-bottom: 40px;">
        <div class="col">
            <h4 style="width: 100%; text-align: center; margin-top: 30px; margin-bottom: 50px;">NUESTRO TRABAJO</h4>
            <div *ngIf="authenticationService.localStorageItem('movil') === 'false'">
                <div *ngFor="let proy of proyectos">
                    <div class="row">
                        <div *ngIf="proy.numero === 1" class="col">
                            <h5 style="width: 100%; text-align: left;">{{proy.titulo}}</h5>
                            <p style="text-align: justify;">{{proy.descripcion}}</p>
                        </div>
                        <div class="col">
                            <ngb-carousel class="slider" [showNavigationArrows]="showNavigationArrows" [showNavigationIndicators]="showNavigationIndicators">
                                <ng-template *ngFor="let slide of proy.slideImg" ngbSlide>
                                    <div class="picsum-img-wrapper">
                                        <img src="{{slide.url}}" style="width: 100%; max-height: 400px;">
                                    </div>
                                </ng-template>
                            </ngb-carousel>
                        </div>
                        <div *ngIf="proy.numero === 2" class="col">
                            <h5 style="width: 100%; text-align: left;">{{proy.titulo}}</h5>
                            <p style="text-align: justify;">{{proy.descripcion}}</p>
                        </div>
                    </div>
                    <div class="row"><div class="col" style="height: 60px;">&nbsp;</div></div>
                </div>
            </div>
            <div *ngIf="authenticationService.localStorageItem('movil') === 'true'">
                <div *ngFor="let proy of proyectos">
                    <div class="row">
                        <div class="col">
                            <h5 style="width: 100%; text-align: left;">{{proy.titulo}}</h5>
                            <p style="text-align: justify;">{{proy.descripcion}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <ngb-carousel class="slider" [showNavigationArrows]="showNavigationArrows" [showNavigationIndicators]="showNavigationIndicators">
                                <ng-template *ngFor="let slide of proy.slideImg" ngbSlide>
                                    <div class="picsum-img-wrapper">
                                        <img src="{{slide.url}}" style="width: 100%; max-height: 400px;">
                                    </div>
                                </ng-template>
                            </ngb-carousel>
                        </div>
                    </div>
                    <div class="row"><div class="col" style="height: 60px;">&nbsp;</div></div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-pie></app-pie>
