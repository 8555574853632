<h3 style="margin-bottom: 2%; color: #3D7DC7; margin-top: 20px;">PAISES</h3>
<table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="text-align: center;">
    <ng-container matColumnDef="pais">
        <th mat-header-cell *matHeaderCellDef style="background-color: #5ECEF3; text-align: center; color: white; font-weight: bold;"> País </th>
        <td mat-cell *matCellDef="let element"> {{element.pais}} </td>
    </ng-container>
    <ng-container matColumnDef="estado">
        <th mat-header-cell *matHeaderCellDef style="background-color: #5ECEF3; text-align: center; color: white; font-weight: bold;">Estado</th>
        <td mat-cell *matCellDef="let element">
            <mat-slide-toggle checked="{{element.estado}}" color="#D4691F" (change)="estado(element.id_pais, $event)"></mat-slide-toggle>
        </td>
    </ng-container>
    <ng-container matColumnDef="accion">
        <th mat-header-cell *matHeaderCellDef style="background-color: #5ECEF3; text-align: center; color: white; font-weight: bold;"> Acciones </th>
        <td mat-cell *matCellDef="let element">
            <mat-icon *ngIf="element.estado" (click)="dialogo('EP', element.id_pais)" style="cursor: pointer;" title="Editar">create</mat-icon>
            <mat-icon *ngIf="!element.estado" style="cursor: default; color: lightgrey;" title="Editar">create</mat-icon>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<div style="z-index:5; position : fixed; float: right; bottom: 5%; right: 2%; margin-bottom : 0px;">
    <a mat-fab style="background: #3D7DC7; font-weight: bold; color: white" (click)="dialogo('CP', '')"><mat-icon>add</mat-icon></a>
</div>
<div style="height: 20px;"></div>
