import { Injectable } from '@angular/core'; 
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class CargaImagen{
	public url_servidor = "https://imagen.rademacher.cl/cargaImagen.php";

	constructor(private http: HttpClient){}

	public postFileImagen(idP: string, numero: number, imagenParaSubir: File){
		const formData = new FormData();
		const fecha = new Date();
		const tipo = imagenParaSubir.type.split('/');
        
        formData.append('imagenPropia', imagenParaSubir, idP + '_' + fecha.getTime() + '_' + numero + '.' + tipo[1]); 
        
		return this.http.post(this.url_servidor, formData);
	}
}
