import { Component, OnInit, Inject } from '@angular/core';
import { GeneralService } from '../../../_service/general.service';
import { CargaImagen } from './../../../_service/cargaImagen.service';
import { DashboardComponent } from '../dashboard.component';
import { MatTableDataSource } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import * as firebase from 'firebase/app';

export interface Dialogo {
  acc: string;
  idP: string;
  tit: string;
  btn: string;
}

@Component({
  selector: 'app-portafolio',
  templateUrl: './portafolio.component.html',
  styleUrls: ['./portafolio.component.scss']
})
export class PortafolioDComponent implements OnInit {
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['empresa', 'titulo', 'imagen', 'publicar', 'estado', 'accion'];

  constructor(private generalService: GeneralService, public dialog: MatDialog, public dashboard: DashboardComponent) {
    this.dashboard.load = 'S';
  }

  dialogo(accion, id): void {
    let titulo = '';
    let boton = '';

    switch (accion) {
      case 'CP':
        titulo = 'Nuevo Proyecto';
        boton = 'Crear';
        break;
      case 'CIP':
        titulo = 'Galería imagenes';
        break;
      case 'EP':
        titulo = 'Editar Proyecto';
        boton = 'Editar';
        break;
    }
    this.dialog.open(DialogoPo, {
      width: '70%',
      data: {acc: accion, idP: id, tit: titulo, btn: boton}
    });
  }

  ngOnInit() {
    this.generalService.scAllDocumentsByOrder('t_proyecto', 'fecha_crea').subscribe((proyectos: any) => {
      if (proyectos.length > 0) {
        let cant = 1;

        proyectos.forEach((proyecto: any) => {
          this.generalService.getAllDocumentsConditional('t_empresa', 'id_empresa', '==', proyecto.id_empresa).subscribe((empresa: any) => {
            this.generalService.getAllDocumentsConditional2('t_proyecto_img', 'id_proyecto', '==', proyecto.id_proyecto, 'estado', '==', true).subscribe((imagenes: any) => {
              proyecto.id_empresa = empresa[0].nombre;
              proyecto.cant_img = imagenes.length;

              if (cant === proyectos.length) {
                this.dataSource = new MatTableDataSource(proyectos);
                this.dashboard.load = 'N';
              }
              cant++;
            });
          });
        });
      } else {
        this.dataSource = new MatTableDataSource(proyectos);
        this.dashboard.load = 'N';
      }
    });
  }

  estado(id, evento) {
    // ***** Editar estado *****
    const obj = {
      estado: evento.checked
    };
    this.generalService.updateDocument('t_proyecto', id, obj).then(success => { });
  }

  publicar(id, evento) {
    // ***** Editar estado *****
    const obj = {
      publicar: evento.checked
    };
    this.generalService.updateDocument('t_proyecto', id, obj).then(success => { });
  }
}

@Component({
  selector: 'app-dialogo',
  templateUrl: 'dialogo.component.html',
})
export class DialogoPo {
  proyecto = {
    descripcion: '',
    estado: true,
    fecha_crea: firebase.firestore.FieldValue.serverTimestamp(),
    id_empresa: '',
    id_proyecto: '',
    publicar: false,
    titulo: ''
  };
  empresas = [];
  imagenes = [];
  public respuestaImagenEnviada;
  public resultadoCarga;

  constructor(private generalService: GeneralService, private snackBar: MatSnackBar, private enviandoImagen: CargaImagen,
              public dialogo: MatDialogRef<DialogoPo>, @Inject(MAT_DIALOG_DATA) public dialog: Dialogo) {
    if (dialog.acc === 'CP' || dialog.acc === 'EP') {
      this.generalService.getAllDocumentsByOrder('t_empresa', 'nombre').subscribe((empresas: any) => {
        this.empresas = [];

        empresas.forEach((empresa: any) => {
          if (empresa.id_empresa !== '') {
            let objE = {
              id_empresa: empresa.id_empresa,
              nombre: empresa.nombre
            };
            this.empresas.push(objE);
          }
        });
      });
  
      if (dialog.acc === 'EP') {
        this.generalService.getAllDocumentsConditional('t_proyecto', 'id_proyecto', '==', dialog.idP).subscribe((proyecto: any) => {
          delete proyecto[0].id;
          this.proyecto = proyecto[0];
        });
      }
    } else {
      this.generalService.getAllDocumentsConditional2('t_proyecto_img', 'id_proyecto', '==', dialog.idP, 'estado', '==', true).subscribe((imagenes: any) => {
        let num = 1;
        let numF = 1;
        let objI = {
          idImg1: '',
          imagen1: '',
          idImg2: '',
          imagen2: '',
          idImg3: '',
          imagen3: '',
          idImg4: '',
          imagen4: ''
        };
        this.imagenes = [];

        imagenes.forEach((imagen: any) => {
          switch (num) {
            case 1:
              objI = {
                idImg1: '',
                imagen1: '',
                idImg2: '',
                imagen2: '',
                idImg3: '',
                imagen3: '',
                idImg4: '',
                imagen4: ''
              };
              objI.idImg1 = imagen.id_proyecto_img;
              objI.imagen1 = imagen.url;

              if (numF === imagenes.length) {
                this.imagenes.push(objI);
                num = 0;
              }
              break;
            case 2:
              objI.idImg2 = imagen.id_proyecto_img;
              objI.imagen2 = imagen.url;

              if (numF === imagenes.length) {
                this.imagenes.push(objI);
                num = 0;
              }
              break;
            case 3:
              objI.idImg3 = imagen.id_proyecto_img;
              objI.imagen3 = imagen.url;

              if (numF === imagenes.length) {
                this.imagenes.push(objI);
                num = 0;
              }
              break;
            case 4:
              objI.idImg4 = imagen.id_proyecto_img;
              objI.imagen4 = imagen.url;
              this.imagenes.push(objI);
              num = 0;
              break;
          }
          num++;
          numF++;
        });
      });
    }
  }

  cerrar() {
    this.dialogo.close();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  accion() {
    switch (this.dialog.acc) {
      case 'CP':
        this.generalService.newDocument(this.proyecto, 't_proyecto').then(successI => {
          const update = {
            id_proyecto: successI.id
          };
          this.generalService.updateDocument('t_proyecto', successI.id, update).then(successU2 => {
            this.openSnackBar('El proyecto se ha creado correctamente.', 'Nuevo Proyecto');
            this.dialogo.close();
          });
        });
        break;
      case 'EP':
        this.generalService.updateDocument('t_proyecto', this.proyecto.id_proyecto, this.proyecto).then(successU2 => {
          this.openSnackBar('El proyecto se ha editado correctamente.', 'Editar Proyecto');
          this.dialogo.close();
        });
        break;
    }
  }

  public cargandoImagen(files: FileList, idProyecto) {
    let i = 0;

    while (i < files.length) {
      this.enviandoImagen.postFileImagen(idProyecto, i, files[i]).subscribe(
        response => {
          this.respuestaImagenEnviada = response; 
          if (this.respuestaImagenEnviada <= 1) {
            console.log("Error en el servidor"); 
          } else {
            if (this.respuestaImagenEnviada.code == 200 && this.respuestaImagenEnviada.status == "success") {
              this.resultadoCarga = 1;
              const objI = {
                estado: true,
                fecha_crea: firebase.firestore.FieldValue.serverTimestamp(),
                id_proyecto: idProyecto,
                id_proyecto_img: '',
                url: 'https://imagen.rademacher.cl/proyecto/' + this.respuestaImagenEnviada.msj
              };
              this.generalService.newDocument(objI, 't_proyecto_img').then(successI => {
                const update = {
                  id_proyecto_img: successI.id
                };
                this.generalService.updateDocument('t_proyecto_img', successI.id, update).then(successU2 => {
                  this.openSnackBar('La imagen se ha subido correctamente.', 'Galería');
                  // this.dialogo.close();
                });
              });
            } else {
              this.resultadoCarga = 2;
            }
          }
        },
        error => {
          console.log(<any>error);
        }
      );
      i++;
    }
  }

  borrar(idImg) {
    const update = {
      estado: false
    };
    this.generalService.updateDocument('t_proyecto_img', idImg, update).then(successU2 => { });
  }
}
