import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../_service/general.service';
import { AuthenticationService } from '../../_service/authentication.service';

@Component({
  selector: 'app-portafolio',
  templateUrl: './portafolio.component.html',
  styleUrls: ['./portafolio.component.scss']
})
export class PortafolioComponent implements OnInit {
  proyectos = [];
  showNavigationArrows = false;
  showNavigationIndicators = false;

  constructor(private generalService: GeneralService, public authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.generalService.scAllDocumentsConditional2('t_proyecto', 'publicar', '==', true, 'estado', '==', true).subscribe((proyectos: any) => {
      let num = 2;

      proyectos.forEach((proyecto: any) => {
        this.generalService.getAllDocumentsConditional2('t_proyecto_img', 'id_proyecto', '==', proyecto.id_proyecto, 'estado', '==', true).subscribe((imagenes: any) => {
          let objP = {
            descripcion: proyecto.descripcion,
            numero: num,
            slideImg: imagenes,
            titulo: proyecto.titulo
          };
          this.proyectos.push(objP);

          if (num === 1) {
            num = 2;
          } else {
            num = 1;
          }
        });
      });
    });
  }
}
