import { Component, OnInit, Inject } from '@angular/core';
import { GeneralService } from '../../../_service/general.service';
import { DashboardComponent } from '../dashboard.component';
import { MatTableDataSource } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import * as firebase from 'firebase/app';

export interface Dialogo {
  acc: string;
  idR: string;
  tit: string;
  btn: string;
}

@Component({
  selector: 'app-region',
  templateUrl: './region.component.html',
  styleUrls: ['./region.component.scss']
})
export class RegionComponent implements OnInit {
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['region', 'pais', 'estado', 'accion'];
  paises = [];
  regiones = [];

  constructor(private generalService: GeneralService, public dialog: MatDialog, public dashboard: DashboardComponent) {
    this.dashboard.load = 'S';
  }

  dialogo(accion, id): void {
    let titulo = '';
    let boton = '';

    switch (accion) {
      case 'CR':
        titulo = 'Nueva Región';
        boton = 'Crear';
        break;
      case 'ER':
        titulo = 'Editar Región';
        boton = 'Editar';
        break;
    }
    this.dialog.open(DialogoRe, {
      width: '70%',
      data: {acc: accion, idR: id, tit: titulo, btn: boton}
    });
  }

  ngOnInit() {
    this.generalService.scAllDocumentsConditionalByOrder('t_region', 'id_empresa', '==', '', 'region').subscribe((regiones: any) => {
      let cant = 1;
      this.regiones = [];

      if (regiones.length > 0) {
        regiones.forEach((region: any) => {
          this.generalService.getAllDocumentsConditional('t_pais', 'id_pais', '==', region.id_pais).subscribe((pais: any) => {
            if (cant === 1) {
              this.regiones = [];
            }

            let objR = {
              pais: pais[0].pais,
              id_region: region.id_region,
              region: region.region,
              estado: region.estado
            };
            this.regiones.push(objR);
    
            if (regiones.length === cant) {
              this.dataSource = new MatTableDataSource(this.regiones);
              this.dashboard.load = 'N';
            }
            cant++;
          });
        });
      } else {
        this.dataSource = new MatTableDataSource(this.regiones);
        this.dashboard.load = 'N';
      }
    });
  }

  estado(id, evento) {
    // ***** Editar estado *****
    const obj = {
      estado: evento.checked
    };
    this.generalService.updateDocument('t_region', id, obj).then(success => { });
  }
}

@Component({
  selector: 'app-dialogo',
  templateUrl: 'dialogo.component.html',
})
export class DialogoRe {
  region = {
    estado: true,
    fecha_crea: firebase.firestore.FieldValue.serverTimestamp(),
    id_empresa: '',
    id_pais: '',
    id_region: '',
    region: ''
  };
  paises = [];

  constructor(private generalService: GeneralService, private snackBar: MatSnackBar,
              public dialogo: MatDialogRef<DialogoRe>, @Inject(MAT_DIALOG_DATA) public dialog: Dialogo) {
    this.generalService.getAllDocumentsConditionalByOrder('t_pais', 'id_empresa', '==', '', 'pais').subscribe((paises: any) => {
      this.paises = paises;
    });

    if (dialog.acc === 'ER') {
      this.generalService.getAllDocumentsConditional('t_region', 'id_region', '==', dialog.idR).subscribe((region: any) => {
        delete region[0].id;
        this.region = region[0];
      });
    }
  }

  cerrar() {
    this.dialogo.close();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  accion() {
    switch (this.dialog.acc) {
      case 'CR':
        this.generalService.newDocument(this.region, 't_region').then(successI => {
          const update = {
            id_region: successI.id
          };
          this.generalService.updateDocument('t_region', successI.id, update).then(successU2 => {
            this.openSnackBar('La región se ha creado correctamente.', 'Nuevo Región');
            this.dialogo.close();
          });
        });
        break;
      case 'ER':
        this.generalService.updateDocument('t_region', this.region.id_region, this.region).then(successU2 => {
          this.openSnackBar('La región se ha editado correctamente.', 'Editar Región');
          this.dialogo.close();
        });
        break;
    }
  }
}
