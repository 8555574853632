<div *ngIf="load === 'S'" style="position: absolute; top: 0px; left: 0px; width: 100%; height: 100%; background: linear-gradient(0deg, rgba(3, 92, 194, 0.5), rgba(149, 241, 241, 0.5)); z-index: 999;">
  <div style="margin-left: 45%; margin-top: 30%;">
    <img src="../../assets/video/cargarRA.gif" style="width: 7rem; height: 7rem;" alt="...">
  </div>
</div>
<div class="container-fluid">
  <div class="row">
    <nav class="col-md-2 d-none d-md-block bg-light sidebar" style="min-height: 85vh;">
      <div class="sidebar-sticky">
        <h6 routerLink="pais" class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1" style="color: #3D7DC7; cursor: pointer; font-size: 1.2vw;">
          <mat-icon>map</mat-icon>&nbsp;Paises
        </h6>
        <h6 routerLink="region" class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1" style="color: #3D7DC7; cursor: pointer; font-size: 1.2vw;">
          <mat-icon>landscape</mat-icon>&nbsp;Regiones
        </h6>
        <h6 routerLink="comuna" class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1" style="color: #3D7DC7; cursor: pointer; font-size: 1.2vw;">
          <mat-icon>place</mat-icon>&nbsp;Comunas
        </h6>
        <h6 routerLink="empresa" class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1" style="color: #3D7DC7; cursor: pointer; font-size: 1.2vw;">
          <mat-icon>domain</mat-icon>&nbsp;Empresas
        </h6>
        <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1" style="color: #3D7DC7; cursor: pointer; font-size: 1.2vw;">
          <mat-icon>local_offer</mat-icon>&nbsp;Promociones
        </h6>
        <h6 routerLink="portafolioD" class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1" style="color: #3D7DC7; cursor: pointer; font-size: 1.2vw;">
          <mat-icon>work</mat-icon>&nbsp;Portafolio
        </h6>
        <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1" style="color: #3D7DC7; cursor: pointer; font-size: 1.2vw;">
          <mat-icon>people</mat-icon>&nbsp;Usuarios
        </h6>
      </div>
    </nav>
    <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-4">
      <router-outlet></router-outlet>
    </main>
      <!--<main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-4">
        <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 class="h2">Dashboard</h1>
          <div class="btn-toolbar mb-2 mb-md-0">
            <div class="btn-group mr-2">
              <button type="button" class="btn btn-sm btn-outline-secondary">Share</button>
              <button type="button" class="btn btn-sm btn-outline-secondary">Export</button>
            </div>
            <button type="button" class="btn btn-sm btn-outline-secondary dropdown-toggle">
              <span data-feather="calendar"></span>
              This week
            </button>
          </div>
        </div>
  
        <canvas class="my-4 w-100" id="myChart" width="900" height="380"></canvas>
  
        <h2>Section title</h2>
        <div class="table-responsive">
          <table class="table table-striped table-sm">
            <thead>
              <tr>
                <th>#</th>
                <th>Header</th>
                <th>Header</th>
                <th>Header</th>
                <th>Header</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1,001</td>
                <td>Lorem</td>
                <td>ipsum</td>
                <td>dolor</td>
                <td>sit</td>
              </tr>
              <tr>
                <td>1,002</td>
                <td>amet</td>
                <td>consectetur</td>
                <td>adipiscing</td>
                <td>elit</td>
              </tr>
              <tr>
                <td>1,003</td>
                <td>Integer</td>
                <td>nec</td>
                <td>odio</td>
                <td>Praesent</td>
              </tr>
              <tr>
                <td>1,003</td>
                <td>libero</td>
                <td>Sed</td>
                <td>cursus</td>
                <td>ante</td>
              </tr>
              <tr>
                <td>1,004</td>
                <td>dapibus</td>
                <td>diam</td>
                <td>Sed</td>
                <td>nisi</td>
              </tr>
              <tr>
                <td>1,005</td>
                <td>Nulla</td>
                <td>quis</td>
                <td>sem</td>
                <td>at</td>
              </tr>
              <tr>
                <td>1,006</td>
                <td>nibh</td>
                <td>elementum</td>
                <td>imperdiet</td>
                <td>Duis</td>
              </tr>
              <tr>
                <td>1,007</td>
                <td>sagittis</td>
                <td>ipsum</td>
                <td>Praesent</td>
                <td>mauris</td>
              </tr>
              <tr>
                <td>1,008</td>
                <td>Fusce</td>
                <td>nec</td>
                <td>tellus</td>
                <td>sed</td>
              </tr>
              <tr>
                <td>1,009</td>
                <td>augue</td>
                <td>semper</td>
                <td>porta</td>
                <td>Mauris</td>
              </tr>
              <tr>
                <td>1,010</td>
                <td>massa</td>
                <td>Vestibulum</td>
                <td>lacinia</td>
                <td>arcu</td>
              </tr>
              <tr>
                <td>1,011</td>
                <td>eget</td>
                <td>nulla</td>
                <td>Class</td>
                <td>aptent</td>
              </tr>
              <tr>
                <td>1,012</td>
                <td>taciti</td>
                <td>sociosqu</td>
                <td>ad</td>
                <td>litora</td>
              </tr>
              <tr>
                <td>1,013</td>
                <td>torquent</td>
                <td>per</td>
                <td>conubia</td>
                <td>nostra</td>
              </tr>
              <tr>
                <td>1,014</td>
                <td>per</td>
                <td>inceptos</td>
                <td>himenaeos</td>
                <td>Curabitur</td>
              </tr>
              <tr>
                <td>1,015</td>
                <td>sodales</td>
                <td>ligula</td>
                <td>in</td>
                <td>libero</td>
              </tr>
            </tbody>
          </table>
        </div>
      </main>-->
  </div>
</div>
