<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" href="#"><img src="../../../assets/img/logo.png" height="60px" alt=""></a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div *ngIf="authenticationService.localStorageItem('login') === 'false'" class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto" style="width: 100%; font-size: 20px; font-weight: bold; color: black;">
      <li class="nav-item" style="width: 10%; text-align: center;" (click)="cerrarM()">
        <a class="nav-link" routerLink="">Inicio</a>
      </li>
      <li *ngIf="authenticationService.localStorageItem('movil') === 'false'"  class="nav-item" style="width: 50%; text-align: center;">&nbsp;</li>
      <li class="nav-item" style="width: 15%; text-align: center;" (click)="cerrarM()">
        <a class="nav-link" routerLink="portafolio">Portafolio</a>
      </li>
      <li class="nav-item" style="width: 15%; text-align: center;" (click)="cerrarM()">
        <a class="nav-link" routerLink="contacto">Contacto</a>
      </li>
      <li *ngIf="authenticationService.localStorageItem('movil') === 'false'" class="nav-item" style="width: 10%; text-align: center;">
        <a class="nav-link" routerLink="login">Log In</a>
      </li>
    </ul>
  </div>
  <div *ngIf="authenticationService.localStorageItem('login') === 'true'" class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto" style="width: 100%;">
      <li class="nav-item" style="width: 30%; text-align: center;">&nbsp;</li>
      <li class="nav-item" style="width: 10%; text-align: center;">
        <mat-icon routerLink="configuracion" title="Configuración" style="font-size: 30px; margin-left: 2%; cursor: pointer; color: #5ECEF3;">settings</mat-icon>
      </li>
      <li class="nav-item" style="width: 10%; text-align: left;">
        <mat-icon routerLink="perfil" title="Perfil" style="font-size: 30px; margin-left: 2%; cursor: pointer; color: #5ECEF3;">person</mat-icon>
      </li>
      <li class="nav-item" style="width: 40%; text-align: right; margin-top: 10px;">
        <h5>{{ authenticationService.localStorageItem('nombre') }}</h5>
      </li>
      <li class="nav-item" style="width: 10%; text-align: center;">
        <mat-icon routerLink="dashboard" title="Dashboard" style="font-size: 30px; margin-left: 2%; cursor: pointer">dashboard</mat-icon>
      </li>
      <li class="nav-item" style="width: 10%; text-align: center;">
        <mat-icon (click)="signOut()" title="Cerrar sesión" style="font-size: 30px; margin-left: 2%; cursor: pointer">clear</mat-icon>
      </li>
    </ul>
  </div>
</nav>
