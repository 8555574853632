import { Component, OnInit, Inject } from '@angular/core';
import { GeneralService } from '../../../_service/general.service';
import { DashboardComponent } from '../dashboard.component';
import { MatTableDataSource } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import * as firebase from 'firebase/app';

export interface Dialogo {
  acc: string;
  idP: string;
  tit: string;
  btn: string;
}

@Component({
  selector: 'app-pais',
  templateUrl: './pais.component.html',
  styleUrls: ['./pais.component.scss']
})
export class PaisComponent implements OnInit {
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['pais', 'estado', 'accion'];
  paises = [];

  constructor(private generalService: GeneralService, public dialog: MatDialog, public dashboard: DashboardComponent) {
    this.dashboard.load = 'S';
  }

  dialogo(accion, id): void {
    let titulo = '';
    let boton = '';

    switch (accion) {
      case 'CP':
        titulo = 'Nuevo País';
        boton = 'Crear';
        break;
      case 'EP':
        titulo = 'Editar País';
        boton = 'Editar';
        break;
    }
    this.dialog.open(DialogoPa, {
      width: '70%',
      data: {acc: accion, idP: id, tit: titulo, btn: boton}
    });
  }

  ngOnInit() {
    this.generalService.scAllDocumentsConditionalByOrder('t_pais', 'id_empresa', '==', '', 'pais').subscribe((paises: any) => {
      let cant = 1;
      this.paises = [];

      if (paises.length > 0) {
        paises.forEach((pais: any) => {
          if (cant === 1) {
            this.paises = [];
          }

          let objP = {
            id_pais: pais.id_pais,
            pais: pais.pais,
            estado: pais.estado
          };
          this.paises.push(objP);
  
          if (paises.length === cant) {
            this.dataSource = new MatTableDataSource(this.paises);
            this.dashboard.load = 'N';
          }
          cant++;
        });
      } else {
        this.dataSource = new MatTableDataSource(this.paises);
        this.dashboard.load = 'N';
      }
    });
  }

  estado(id, evento) {
    // ***** Editar estado *****
    const obj = {
      estado: evento.checked
    };
    this.generalService.updateDocument('t_pais', id, obj).then(success => { });
  }
}

@Component({
  selector: 'app-dialogo',
  templateUrl: 'dialogo.component.html',
})
export class DialogoPa {
  pais = {
    estado: true,
    fecha_crea: firebase.firestore.FieldValue.serverTimestamp(),
    id_empresa: '',
    id_pais: '',
    moneda: '',
    pais: '',
    te_guio_1: '',
    te_guio_2: '',
    te_guio_3: ''
  };

  constructor(private generalService: GeneralService, private snackBar: MatSnackBar,
              public dialogo: MatDialogRef<DialogoPa>, @Inject(MAT_DIALOG_DATA) public dialog: Dialogo) {
    if (dialog.acc === 'EP') {
      this.generalService.getAllDocumentsConditional('t_pais', 'id_pais', '==', dialog.idP).subscribe((pais: any) => {
        delete pais[0].id;
        this.pais = pais[0];
      });
    }
  }

  cerrar() {
    this.dialogo.close();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  accion() {
    switch (this.dialog.acc) {
      case 'CP':
        this.generalService.newDocument(this.pais, 't_pais').then(successI => {
          const update = {
            id_pais: successI.id
          };
          this.generalService.updateDocument('t_pais', successI.id, update).then(successU2 => {
            this.openSnackBar('El país se ha creado correctamente.', 'Nuevo País');
            this.dialogo.close();
          });
        });
        break;
      case 'EP':
        this.generalService.updateDocument('t_pais', this.pais.id_pais, this.pais).then(successU2 => {
          this.openSnackBar('El país se ha editado correctamente.', 'Editar País');
          this.dialogo.close();
        });
        break;
    }
  }
}