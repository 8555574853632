import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../environments/environment';
import { GeneralService } from './_service/general.service';

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule, MatInputModule } from '@angular/material';
import { HttpClientModule } from '@angular/common/http';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CargaImagen } from './_service/cargaImagen.service';
import { DashboardComponent } from './intranet/dashboard/dashboard.component';
import { EncabezadoComponent } from './web/encabezado/encabezado.component';
import { HomeComponent } from './web/home/home.component';
import { PieComponent } from './web/pie/pie.component';
import { ContactoComponent } from './web/contacto/contacto.component';
import { SendEmailService } from './_service/send-email.service';
import { PortafolioComponent } from './web/portafolio/portafolio.component';
import { PaisComponent } from './intranet/dashboard/pais/pais.component';
import { DialogoPa } from './intranet/dashboard/pais/pais.component';
import { RegionComponent } from './intranet/dashboard/region/region.component';
import { DialogoRe } from './intranet/dashboard/region/region.component';
import { ComunaComponent } from './intranet/dashboard/comuna/comuna.component';
import { DialogoCo } from './intranet/dashboard/comuna/comuna.component';
import { EmpresaComponent } from './intranet/dashboard/empresa/empresa.component';
import { DialogoEm } from './intranet/dashboard/empresa/empresa.component';
import { LoginComponent } from './intranet/login/login.component';
import { PromocionComponent } from './intranet/dashboard/promocion/promocion.component';
import { UsuarioComponent } from './intranet/dashboard/usuario/usuario.component';
import { PortafolioDComponent } from './intranet/dashboard/portafolio/portafolio.component';
import { DialogoPo } from './intranet/dashboard/portafolio/portafolio.component';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    EncabezadoComponent,
    HomeComponent,
    PieComponent,
    ContactoComponent,
    PortafolioComponent,
    PaisComponent,
    DialogoPa,
    RegionComponent,
    DialogoRe,
    ComunaComponent,
    DialogoCo,
    EmpresaComponent,
    DialogoEm,
    LoginComponent,
    PromocionComponent,
    UsuarioComponent,
    PortafolioDComponent,
    DialogoPo
  ],
  imports: [
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    HttpClientModule,
    MatCardModule,
    MatTableModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatButtonModule,
    NgbModule
  ],
  entryComponents: [
    DialogoCo,
    DialogoEm,
    DialogoPa,
    DialogoRe,
    DialogoPo
  ],
  providers: [GeneralService, SendEmailService, CargaImagen,
              { provide: LocationStrategy, useClass: HashLocationStrategy }],
  bootstrap: [AppComponent]
})
export class AppModule { }
