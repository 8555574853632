<div style="width: 100%;"><img src="../../../assets/img/barra.jpg" width="100%" height="120px" alt=""></div>
<div class="container" style="background-image: linear-gradient(rgba(255, 255, 255, 0.8),rgba(255, 255, 255, 0.8)), url('../../../assets/img/sobre.png');
background-repeat: no-repeat; background-position: center center; min-height: 600px; padding-bottom: 50px;">
  <section>
    <div class="air"></div>
    <div class="forms">
      <h5 class="text-center" style="color: #3D7DC7; font-weight: bold; padding-top: 30px;">Formulario de Contacto</h5>
      <form (ngSubmit)="onSubmit(contactForm, 'Contacto');" #contactForm="ngForm">
        <mat-form-field>
          <input matInput placeholder="Nombre" #nombre maxlength="80" [(ngModel)]="ContactModel.name" name="Name" required>
          <mat-hint align="end">{{nombre.value?.length || 0}}/80</mat-hint>
        </mat-form-field>
        <mat-form-field>
          <input matInput placeholder="Email" type="email" [(ngModel)]="ContactModel.email" name="Email" [email]="true" required>
        </mat-form-field>
        <mat-form-field>
          <input matInput placeholder="Teléfono" #tel type="tel" [(ngModel)]="ContactModel.phone" name="Phone" maxlength="16">
          <mat-hint align="end">{{tel.value?.length || 0}}/16</mat-hint>
        </mat-form-field>
        <mat-form-field>
          <input matInput placeholder="Asunto" #asunto maxlength="60" [(ngModel)]="ContactModel.subject" name="Subject" required>
          <mat-hint align="end">{{asunto.value?.length || 0}}/60</mat-hint>
        </mat-form-field>
        <mat-form-field>
          <textarea matInput placeholder="Mensaje" #msn maxlength="300" [(ngModel)]="ContactModel.msn" name="Msn" cols="25" required></textarea>
          <mat-hint align="end">{{msn.value?.length || 0}}/300</mat-hint>
        </mat-form-field>
        <button mat-raised-button color="primary" [disabled]="!contactForm.form.valid">Enviar</button>
      </form>
    </div>
  </section>
</div>
<app-pie></app-pie>
