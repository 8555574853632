<h3 style="margin-bottom: 2%; color: #3D7DC7; margin-top: 20px;">EMPRESAS</h3>
<table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="text-align: center;">
    <ng-container matColumnDef="rut">
        <th mat-header-cell *matHeaderCellDef style="background-color: #5ECEF3; text-align: center; color: white; font-weight: bold;"> Rut </th>
        <td mat-cell *matCellDef="let element"> {{element.rut}} </td>
    </ng-container>
    <ng-container matColumnDef="empresa">
        <th mat-header-cell *matHeaderCellDef style="background-color: #5ECEF3; text-align: center; color: white; font-weight: bold;"> Empresa </th>
        <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
    </ng-container>
    <ng-container matColumnDef="pais">
        <th mat-header-cell *matHeaderCellDef style="background-color: #5ECEF3; text-align: center; color: white; font-weight: bold;"> País </th>
        <td mat-cell *matCellDef="let element"> {{element.pais}} </td>
    </ng-container>
    <ng-container matColumnDef="ciudad">
        <th mat-header-cell *matHeaderCellDef style="background-color: #5ECEF3; text-align: center; color: white; font-weight: bold;"> Ciudad </th>
        <td mat-cell *matCellDef="let element"> {{element.ciudad}} </td>
    </ng-container>
    <ng-container matColumnDef="logo">
        <th mat-header-cell *matHeaderCellDef style="background-color: #5ECEF3; text-align: center; color: white; font-weight: bold;"> Logo </th>
        <td mat-cell *matCellDef="let element"> {{element.url_logo}} </td>
    </ng-container>
    <ng-container matColumnDef="modulos">
        <th mat-header-cell *matHeaderCellDef style="background-color: #5ECEF3; text-align: center; color: white; font-weight: bold;"> Módulos </th>
        <td mat-cell *matCellDef="let element"> {{element.modulos}}&nbsp;&nbsp;<mat-icon style="cursor: pointer;" title="Agregar módulo">add</mat-icon></td>
    </ng-container>
    <ng-container matColumnDef="estado">
        <th mat-header-cell *matHeaderCellDef style="background-color: #5ECEF3; text-align: center; color: white; font-weight: bold;">Estado</th>
        <td mat-cell *matCellDef="let element">
            <mat-slide-toggle checked="{{element.estado}}" color="#D4691F" (change)="estado(element.id_empresa, $event)"></mat-slide-toggle>
        </td>
    </ng-container>
    <ng-container matColumnDef="accion">
        <th mat-header-cell *matHeaderCellDef style="background-color: #5ECEF3; text-align: center; color: white; font-weight: bold;"> Acciones </th>
        <td mat-cell *matCellDef="let element">
            <mat-icon *ngIf="element.estado" (click)="dialogo('EE', element.id_empresa)" style="cursor: pointer;" title="Editar">create</mat-icon>
            <mat-icon *ngIf="!element.estado" style="cursor: default; color: lightgrey;" title="Editar">create</mat-icon>&nbsp;&nbsp;
            <mat-icon (click)="dialogo('VE', element.id_empresa)" style="cursor: pointer;" title="Ver">visibility</mat-icon>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<div style="z-index:5; position : fixed; float: right; bottom: 5%; right: 2%; margin-bottom : 0px;">
    <a mat-fab style="background: #3D7DC7; font-weight: bold; color: white" (click)="dialogo('CE', '')"><mat-icon>add</mat-icon></a>
</div>
<div style="height: 20px;"></div>
