<p><mat-icon (click)="cerrar()" style="float: right; cursor: pointer;">highlight_off</mat-icon></p>
<h1 mat-dialog-title style="text-align: center;">{{dialog.tit}}</h1>
<div mat-dialog-content>
    <div *ngIf="dialog.acc === 'CE' || dialog.acc === 'EE' || dialog.acc === 'VE'">
        <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend" style="width: 20%;">
                <span class="input-group-text" id="txtRut" style="background-color: white; border: none;">Rut</span>
            </div>
            <input type="text" class="form-control" style="width: 20%;" aria-label="Sizing example input" aria-describedby="txtRut" [(ngModel)]="empresa.rut">
            <div class="input-group-prepend" style="width: 20%;">
                <span class="input-group-text" id="txtNombre" style="background-color: white; border: none;">Nombre</span>
            </div>
            <input type="text" class="form-control" style="width: 40%;" aria-label="Sizing example input" aria-describedby="txtNombre" [(ngModel)]="empresa.nombre">
        </div>
        <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend" style="width: 20%;">
                <span class="input-group-text" id="txtRazonS" style="background-color: white; border: none;">Razón social</span>
            </div>
            <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="txtRazonS" [(ngModel)]="empresa.razon_social">
        </div>
        <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend" style="width: 20%;">
                <span class="input-group-text" id="txtGiro" style="background-color: white; border: none;">Giro</span>
            </div>
            <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="txtGiro" [(ngModel)]="empresa.giro">
        </div>
        <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend" style="width: 20%;">
                <span class="input-group-text" id="cbPais" style="background-color: white; border: none;">País</span>
            </div>
            <select class="custom-select" id="cbPais" style="width: 30%;" [(ngModel)]="empresa.id_pais" (change)="cargaReg($event.target.value)">
                <option value="" selected>Seleccionar</option>
                <option *ngFor="let pais of paises" [value]="pais.id_pais">{{pais.pais}}</option>
            </select>
            <div class="input-group-prepend" style="width: 20%;">
                <span class="input-group-text" id="cbRegion" style="background-color: white; border: none;">Región</span>
            </div>
            <select class="custom-select" id="cbRegion" style="width: 30%;" [(ngModel)]="empresa.id_region" (change)="cargaCom($event.target.value)">
                <option value="" selected>Seleccionar</option>
                <option *ngFor="let region of regiones" [value]="region.id_region">{{region.region}}</option>
            </select>
        </div>
        <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend" style="width: 20%;">
                <span class="input-group-text" id="cbComuna" style="background-color: white; border: none;">Comuna</span>
            </div>
            <select class="custom-select" id="cbComuna" style="width: 30%;" [(ngModel)]="empresa.id_comuna">
                <option value="" selected>Seleccionar</option>
                <option *ngFor="let comuna of comunas" [value]="comuna.id_comuna">{{comuna.comuna}}</option>
            </select>
            <div class="input-group-prepend" style="width: 20%;">
                <span class="input-group-text" id="txtCiudad" style="background-color: white; border: none;">Ciudad</span>
            </div>
            <input type="text" class="form-control" style="width: 30%;" aria-label="Sizing example input" aria-describedby="txtCiudad" [(ngModel)]="empresa.ciudad">
        </div>
        <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend" style="width: 20%;">
                <span class="input-group-text" id="txtDireccion" style="background-color: white; border: none;">Dirección</span>
            </div>
            <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="txtDireccion" [(ngModel)]="empresa.direccion">
        </div>
        <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend" style="width: 20%;">
                <span class="input-group-text" id="txtURLWeb" style="background-color: white; border: none;">URL Web</span>
            </div>
            <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="txtURLWeb" [(ngModel)]="empresa.url_web">
        </div>
        <div style="border-bottom: 2px solid #3D7DC7; width: 100%; text-align: left; margin-top: 5px; margin-bottom: 5px;">Contacto 1</div>
        <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend" style="width: 20%;">
                <span class="input-group-text" style="width: 40%;" id="txtNombre1" style="background-color: white; border: none;">Nombre</span>
            </div>
            <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="txtNombre1" [(ngModel)]="empresa.contacto_01">
            <div class="input-group-prepend" style="width: 20%;">
                <span class="input-group-text" id="txtFono1" style="background-color: white; border: none;">Teléfono</span>
            </div>
            <input type="number" class="form-control" style="width: 20%;" aria-label="Sizing example input" aria-describedby="txtFono1" [(ngModel)]="empresa.telefono_01">
        </div>
        <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend" style="width: 20%;">
                <span class="input-group-text" id="txtEmail1" style="background-color: white; border: none;">Mail</span>
            </div>
            <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="txtEmail1" [(ngModel)]="empresa.email_01">
        </div>
        <div style="border-bottom: 2px solid #3D7DC7; width: 100%; text-align: left; margin-top: 5px; margin-bottom: 5px;">Contacto 2</div>
        <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend" style="width: 20%;">
                <span class="input-group-text" style="width: 40%;" id="txtNombre2" style="background-color: white; border: none;">Nombre</span>
            </div>
            <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="txtNombre2" [(ngModel)]="empresa.contacto_02">
            <div class="input-group-prepend" style="width: 20%;">
                <span class="input-group-text" id="txtFono2" style="background-color: white; border: none;">Teléfono</span>
            </div>
            <input type="number" class="form-control" style="width: 20%;" aria-label="Sizing example input" aria-describedby="txtFono2" [(ngModel)]="empresa.telefono_02">
        </div>
        <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend" style="width: 20%;">
                <span class="input-group-text" id="txtEmail2" style="background-color: white; border: none;">Mail</span>
            </div>
            <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="txtEmail2" [(ngModel)]="empresa.email_02">
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-button style="background-color: #3D7DC7; color: white; margin: 0 auto;" (click)="accion()">{{dialog.btn}}</button>
</div>
