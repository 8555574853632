import { Component, OnInit, Inject } from '@angular/core';
import { GeneralService } from '../../../_service/general.service';
import { DashboardComponent } from '../dashboard.component';
import { MatTableDataSource } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import * as firebase from 'firebase/app';

export interface Dialogo {
  acc: string;
  idC: string;
  tit: string;
  btn: string;
}

@Component({
  selector: 'app-comuna',
  templateUrl: './comuna.component.html',
  styleUrls: ['./comuna.component.scss']
})
export class ComunaComponent implements OnInit {
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['pais', 'region', 'comuna', 'estado', 'accion'];
  comunas = [];

  constructor(private generalService: GeneralService, public dialog: MatDialog, public dashboard: DashboardComponent) {
    this.dashboard.load = 'S';
  }

  dialogo(accion, id): void {
    let titulo = '';
    let boton = '';

    switch (accion) {
      case 'CC':
        titulo = 'Nueva Comuna';
        boton = 'Crear';
        break;
      case 'EC':
        titulo = 'Editar Comuna';
        boton = 'Editar';
        break;
    }
    this.dialog.open(DialogoCo, {
      width: '70%',
      data: {acc: accion, idC: id, tit: titulo, btn: boton}
    });
  }

  ngOnInit() {
    this.generalService.scAllDocumentsConditionalByOrder('t_comuna', 'id_empresa', '==', '', 'comuna').subscribe((comunas: any) => {
      this.comunas = [];
      let cant = 1;

      if (comunas.length > 0) {
        comunas.forEach((comuna: any) => {
          this.generalService.getAllDocumentsConditional('t_pais', 'id_pais', '==', comuna.id_pais).subscribe((pais: any) => {
            this.generalService.getAllDocumentsConditional('t_region', 'id_region', '==', comuna.id_region).subscribe((region: any) => {
              if (cant === 1) {
                this.comunas = [];
              }
              
              let objC = {
                id_comuna: comuna.id_comuna,
                id_pais: pais[0].pais,
                id_region: region[0].region,
                comuna: comuna.comuna,
                estado: comuna.estado
              };
              this.comunas.push(objC);

              if (comunas.length === cant) {
                this.dataSource = new MatTableDataSource(this.comunas);
                this.dashboard.load = 'N';
              }
              cant++;
            });
          });
        });
      } else {
        this.dataSource = new MatTableDataSource(this.comunas);
        this.dashboard.load = 'N';
      }
    });
  }

  estado(id, evento) {
    // ***** Editar estado *****
    const obj = {
      estado: evento.checked
    };
    this.generalService.updateDocument('t_comuna', id, obj).then(success => { });
  }
}

@Component({
  selector: 'app-dialogo',
  templateUrl: 'dialogo.component.html',
})
export class DialogoCo {
  comuna = {
    estado: true,
    fecha_crea: firebase.firestore.FieldValue.serverTimestamp(),
    id_comuna: '',
    id_empresa: '',
    id_pais: '',
    id_region: '',
    comuna: ''
  };
  regiones = [];
  paises = [];

  constructor(private generalService: GeneralService, private snackBar: MatSnackBar,
              public dialogo: MatDialogRef<DialogoCo>, @Inject(MAT_DIALOG_DATA) public dialog: Dialogo) {
    this.generalService.getAllDocumentsConditional2('t_pais', 'id_empresa', '==', '', 'estado', '==', true).subscribe((paises: any) => {
      paises.forEach((pais: any) => {
        let objP = {
          id_pais: pais.id_pais,
          pais: pais.pais
        };
        this.paises.push(objP);
      });
    });
    
    if (dialog.acc === 'EC') {
      this.generalService.getAllDocumentsConditional('t_comuna', 'id_comuna', '==', dialog.idC).subscribe((comuna: any) => {
        this.generalService.getAllDocumentsConditional2('t_region', 'id_pais', '==', comuna[0].id_pais, 'estado', '==', true).subscribe((regiones: any) => {
          regiones.forEach((region: any) => {
            if (region.id_empresa === localStorage.getItem('id_empresa') || region.id_empresa === '') {
              let objR = {
                id_region: region.id_region,
                region: region.region
              };
              this.regiones.push(objR);
            }
          });
        });
        delete comuna[0].id;
        this.comuna = comuna[0];
      });
    }
  }

  cargaReg(id) {
    this.generalService.getAllDocumentsConditional2('t_region', 'id_pais', '==', id, 'estado', '==', true).subscribe((regiones: any) => {
      this.regiones = [];

      regiones.forEach((region: any) => {
        if (region.id_empresa === localStorage.getItem('id_empresa') || region.id_empresa === '') {
          let objR = {
            id_region: region.id_region,
            region: region.region
          };
          this.regiones.push(objR);
        }
      });
    });
  }

  cerrar() {
    this.dialogo.close();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  accion() {
    switch (this.dialog.acc) {
      case 'CC':
        this.generalService.newDocument(this.comuna, 't_comuna').then(successI => {
          const update = {
            id_empresa: localStorage.getItem('id_empresa'),
            id_comuna: successI.id
          };
          this.generalService.updateDocument('t_comuna', successI.id, update).then(successU2 => {
            this.openSnackBar('La comuna se ha creado correctamente.', 'Nueva Comuna');
            this.dialogo.close();
          });
        });
        break;
      case 'EC':
        this.generalService.updateDocument('t_comuna', this.comuna.id_comuna, this.comuna).then(successU2 => {
          this.openSnackBar('La comuna se ha editado correctamente.', 'Editar Comuna');
          this.dialogo.close();
        });
        break;
    }
  }
}
