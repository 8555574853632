import { Component, OnInit, Inject } from '@angular/core';
import { GeneralService } from '../../../_service/general.service';
import { DashboardComponent } from '../dashboard.component';
import { MatTableDataSource } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import * as firebase from 'firebase/app';

export interface Dialogo {
  acc: string;
  idE: string;
  tit: string;
  btn: string;
}

@Component({
  selector: 'app-empresa',
  templateUrl: './empresa.component.html',
  styleUrls: ['./empresa.component.scss']
})
export class EmpresaComponent implements OnInit {
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['rut', 'empresa', 'pais', 'ciudad', 'logo', 'modulos', 'estado', 'accion'];
  empresas = [];

  constructor(private generalService: GeneralService, public dialog: MatDialog, public dashboard: DashboardComponent) {
    this.dashboard.load = 'S';
  }

  dialogo(accion, id): void {
    let titulo = '';
    let boton = '';

    switch (accion) {
      case 'CE':
        titulo = 'Nueva Empresa';
        boton = 'Crear';
        break;
      case 'EE':
        titulo = 'Editar Empresa';
        boton = 'Editar';
        break;
    }
    this.dialog.open(DialogoEm, {
      width: '70%',
      data: {acc: accion, idE: id, tit: titulo, btn: boton}
    });
  }

  ngOnInit() {
    this.generalService.scAllDocumentsByOrder('t_empresa', 'nombre').subscribe((empresas: any) => {
      this.empresas = [];
      let cant = 1;

      empresas.forEach((empresa: any) => {
        this.generalService.getAllDocumentsConditional('t_pais', 'id_pais', '==', empresa.id_pais).subscribe((pais: any) => {
          let cantM = 0;

          if (empresa.mod_adm === true) {
            cantM++;
          }

          if (empresa.mod_asi === true) {
            cantM++;
          }

          if (empresa.mod_cor === true) {
            cantM++;
          }

          if (empresa.mod_ens === true) {
            cantM++;
          }

          if (empresa.mod_rat === true) {
            cantM++;
          }
          let rut = '';

          if (empresa.rut !== '') {
            rut = empresa.rut;

            if (empresa.rut.length === 9) {
              rut = rut.substring(0, 2) + '.' + rut.substring(2, 5) + '.' + rut.substring(5, 8) + '-' + rut.substring(8, 9);
            } else {
              rut = rut.substring(0, 1) + '.' + rut.substring(1, 4) + '.' + rut.substring(4, 7) + '-' + rut.substring(7, 8);
            }
          }
          let objE = {
            ciudad: empresa.ciudad,
            estado: empresa.estado,
            modulos: cantM,
            nombre: empresa.nombre,
            pais: pais[0].pais,
            rut: rut,
            url_logo: empresa.url_logo
          };
          this.empresas.push(objE);

          if (empresas.length === cant) {
            this.dataSource = new MatTableDataSource(this.empresas);
            this.dashboard.load = 'N';
          }
          cant++;
        });
      });
    });
  }

  estado(id, evento) {
    // ***** Editar estado *****
    const obj = {
      estado: evento.checked
    };
    this.generalService.updateDocument('t_empresa', id, obj).then(success => { });
  }
}

@Component({
  selector: 'app-dialogo',
  templateUrl: 'dialogo.component.html',
})
export class DialogoEm {
  empresa = {
    ciudad: '',
    contacto_01: '',
    contacto_02: '',
    direccion: '',
    email_01: '',
    email_02: '',
    estado: true,
    fecha_crea: firebase.firestore.FieldValue.serverTimestamp(),
    giro: '',
    id_comuna: '',
    id_empresa: '',
    id_pais: '',
    id_region: '',
    mod_adm: false,
    mod_asi: false,
    mod_cor: false,
    mod_ens: false,
    mod_rat: false,
    nombre: '',
    razon_social: '',
    rut: '',
    telefono_01: 0,
    telefono_02: 0,
    url_log: '',
    url_web: ''
  };
  comunas = [];
  regiones = [];
  paises = [];

  constructor(private generalService: GeneralService, private snackBar: MatSnackBar,
              public dialogo: MatDialogRef<DialogoEm>, @Inject(MAT_DIALOG_DATA) public dialog: Dialogo) {
    this.generalService.getAllDocumentsConditional2('t_pais', 'id_empresa', '==', '', 'estado', '==', true).subscribe((paises: any) => {
      paises.forEach((pais: any) => {
        let objP = {
          id_pais: pais.id_pais,
          pais: pais.pais
        };
        this.paises.push(objP);
      });
    });
    
    if (dialog.acc === 'EE') {
      this.generalService.getAllDocumentsConditional('t_empresa', 'id_empresa', '==', dialog.idE).subscribe((empresa: any) => {
        this.generalService.getAllDocumentsConditional3('t_region', 'id_pais', '==', empresa[0].id_pais, 'id_empresa', '==', '', 'estado', '==', true).subscribe((regiones: any) => {
          regiones.forEach((region: any) => {
            let objR = {
              id_region: region.id_region,
              region: region.region
            };
            this.regiones.push(objR);
          });
        });

        this.generalService.getAllDocumentsConditional3('t_comuna', 'id_region', '==', empresa[0].id_region, 'id_empresa', '==', '', 'estado', '==', true).subscribe((comunas: any) => {
          comunas.forEach((comuna: any) => {
            let objC = {
              id_comuna: comuna.id_comuna,
              comuna: comuna.comuna
            };
            this.comunas.push(objC);
          });
        });
      });
    }
  }

  cargaReg(id) {
    this.generalService.getAllDocumentsConditional3('t_region', 'id_empresa', '==', '', 'id_pais', '==', id, 'estado', '==', true).subscribe((regiones: any) => {
      this.regiones = [];

      regiones.forEach((region: any) => {
        let objR = {
          id_region: region.id_region,
          region: region.region
        };
        this.regiones.push(objR);
      });
    });
  }

  cargaCom(id) {
    this.generalService.getAllDocumentsConditional3('t_comuna', 'id_empresa', '==', '', 'id_region', '==', id, 'estado', '==', true).subscribe((comunas: any) => {
      this.comunas = [];

      comunas.forEach((comuna: any) => {
        let objC = {
          id_comuna: comuna.id_comuna,
          comuna: comuna.comuna
        };
        this.comunas.push(objC);
      });
    });
  }

  cerrar() {
    this.dialogo.close();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  accion() {
    switch (this.dialog.acc) {
      case 'CE':
        this.generalService.newDocument(this.empresa, 't_empresa').then(successI => {
          const update = {
            id_empresa: successI.id
          };
          this.generalService.updateDocument('t_empresa', successI.id, update).then(successU2 => {
            this.openSnackBar('La empresa se ha creado correctamente.', 'Nueva Empresa');
            this.dialogo.close();
          });
        });
        break;
      case 'EE':
        this.generalService.updateDocument('t_empresa', this.empresa.id_empresa, this.empresa).then(successU2 => {
          this.openSnackBar('La empresa se ha editado correctamente.', 'Editar Empresa');
          this.dialogo.close();
        });
        break;
    }
  }
}
