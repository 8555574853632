import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, EmailValidator } from '@angular/forms';
import { MailForm } from '../../_service/mailForm';
import { SendEmailService } from '../../_service/send-email.service';
import { MatSnackBar } from '@angular/material';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.scss']
})
export class ContactoComponent implements OnInit {
  email = new FormControl('', [Validators.required, Validators.email]);
  ContactModel = new MailForm();
  private emailResponse;
  private truefalse: boolean = false;

  constructor(private sendServices: SendEmailService, public snackBar: MatSnackBar) { }

  ngOnInit() {
  }

  getErrorMessage() {
    return this.email.hasError('required') ? 'Ingrese un correo electronico' :
      this.email.hasError('email') ? 'Correo invalido' :
        '';
  }

  onSubmit(f: NgForm, tipoF) {
    this.ContactModel.tipo = tipoF;
    this.getSentServices(this.ContactModel, f);
  }

  getSentServices(body: MailForm, f: NgForm) {
    this.sendServices.getResponseEmail(body).subscribe(
      data => {
        if (data) {
          this.snackBar.open('¡Muchas gracias por contactarnos! En menos de 24 hrs te ubicaremos por cielo, mar o tierra.', 'Contacto', {
            duration: 5000,
          });
          f.reset();
        } else {
          this.snackBar.open(':(', 'Error', {
            duration: 2000,
          });
        }
      },
      err => { this.snackBar.open('Algo fallo :/', 'ups', {
        duration: 5000,
      });
    });
  }
}
