import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../_service/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  email = '';
  password = '';

  constructor(public authenticationService: AuthenticationService) { }

  ngOnInit() {
    localStorage.setItem('load', 'N');
  }

  signUp() {
    this.authenticationService.SignUp(this.email, this.password);
    this.email = '';
    this.password = '';
  }

  signIn() {
    localStorage.setItem('load', 'S');
    this.authenticationService.SignIn(this.email, this.password);
    this.email = '';
    this.password = '';
  }

  cgValUsu(e) {
    let email = '';
    const key = e.keyCode || e.which;
    const tecla = String.fromCharCode(key).toLowerCase();

    if ((key !== 8) && (key !== 37) && (key !== 39)) {
      if (this.email !== '') {
        email = this.email + tecla;
      } else {
        email = tecla;
      }
    }
  }
}
