import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pie',
  templateUrl: './pie.component.html',
  styleUrls: ['./pie.component.scss']
})
export class PieComponent implements OnInit {
  agno = 0;
  constructor() { }

  ngOnInit() {
    const fecha = new Date();
    this.agno = fecha.getFullYear();
  }
}
