import { Injectable } from '@angular/core';
import { MailForm } from './mailForm';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';

@Injectable()
export class SendEmailService {
  type: any;
  constructor( private http: HttpClient) { }

  getResponseEmail(Body: MailForm): Observable<any> {
    /*this.http.post('https://accvaldivia.cl/accv_old/mail/enviarMail.php', Body).subscribe(data => {
      console.log(data);
      this.type = JSON.stringify(data);
    });*/
    return this.http.post('https://mail.rademacher.cl/enviarMail.php', Body);
  }
}
