import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../_service/authentication.service';

declare var $: any;

@Component({
  selector: 'app-encabezado',
  templateUrl: './encabezado.component.html',
  styleUrls: ['./encabezado.component.scss']
})
export class EncabezadoComponent implements OnInit {
  scrHeight: any;
  scrWidth: any;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?)
  {
    localStorage.setItem('movil', 'false');
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    localStorage.setItem('width', this.scrWidth);

    if (this.scrWidth < 860) {
      localStorage.setItem('movil', 'true');
    }
  }

  constructor(public authenticationService: AuthenticationService, private router: Router) {
    this.getScreenSize();
  }

  ngOnInit() {
    if (localStorage.getItem('login') === 'true') {
      if (localStorage.getItem('perfil') !== 'Cliente') {
        this.router.navigateByUrl('/dashboard');
      } else {
        this.router.navigateByUrl('');
      }
    } else {
      localStorage.setItem('login', 'false');
      this.router.navigateByUrl('');
    }
  }

  signOut() {
    this.authenticationService.SignOut();
  }

  cerrarM() {
    $('.navbar-collapse').collapse('hide');
  }
}
