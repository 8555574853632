<p><mat-icon (click)="cerrar()" style="float: right; cursor: pointer;">highlight_off</mat-icon></p>
<h1 mat-dialog-title style="text-align: center;">{{dialog.tit}}</h1>
<div mat-dialog-content>
    <div class="input-group input-group-sm mb-3">
        <div class="input-group-prepend" style="width: 30%;">
            <span class="input-group-text" id="cbPais" style="background-color: white; border: none;">País</span>
        </div>
        <select class="custom-select" id="cbPais" style="width: 60%;" [(ngModel)]="region.id_pais">
            <option value="" selected>Seleccionar</option>
            <option *ngFor="let pais of paises" [value]="pais.id_pais">{{pais.pais}}</option>
        </select>
    </div>
    <div class="input-group input-group-sm mb-3">
        <div class="input-group-prepend" style="width: 30%;">
            <span class="input-group-text" id="txtRegion" style="background-color: white; border: none;">Región</span>
        </div>
        <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="txtRegion" [(ngModel)]="region.region">
    </div>
</div>
<div mat-dialog-actions>
    <button mat-button style="background-color: #3D7DC7; color: white; margin: 0 auto;" (click)="accion()">{{dialog.btn}}</button>
</div>
