<div style="width: 100%;"><img src="../../../assets/img/barra.jpg" width="100%" height="120px" alt=""></div>
<div class="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column" style="min-height: 85vh; margin-bottom: 50px;">
  <header class="masthead mb-auto">
    <mat-card class="example-card">
      <mat-card-header>
        <div mat-card-avatar class="example-header-image"></div>
        <mat-card-subtitle>Log In</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <input type="email" [(ngModel)]="email" class="form-control" placeholder="Correo electrónico" style="margin-top: 5px;" required>
        <input type="password" [(ngModel)]="password" class="form-control" placeholder="Contraseña" required>
      </mat-card-content>
      <mat-card-actions>
        <button *ngIf="authenticationService.localStorageItem('load') === 'N'" mat-button style="width: 30%; margin-left: 35%;"  (click)="signIn()">Ingresar</button>
        <div *ngIf="authenticationService.localStorageItem('load') === 'S'" style="width: 30%; margin-left: 35%; text-align: center;">
          <img src="../../../assets/video/cargarRA.gif" class="rounded mx-auto d-block" style="width: 7rem; height: 4rem; text-align: center;" alt="...">
        </div>
      </mat-card-actions>
    </mat-card>
  </header>
</div>
<app-pie></app-pie>
