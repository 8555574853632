<div style="width: 100%;"><img src="../../../assets/img/barra.jpg" width="100%" height="120px" alt=""></div>
<div class="container" style="margin-top: 30px; padding-bottom: 50px;">
  <div class="row">
    <div class="col-md-4" style="margin: 0 auto;">
      <img src="../../../assets/img/logo.png" alt="Image" style="width: 80%; height: auto; margin-left: 10%;">
    </div>
    <div class="col-md-8">
      <p>RA Tech es una Sociedad fundada hace más de 4 años pero que inició sus operaciones formalmente durante el año 2017.</p>
      <p>Sabemos que se puede optimizar el tiempo de trabajo y mejorar la calidad de los servicios de nuestros clientes, con el uso de herramientas adecuadas y correctamente diseñadas para ese fin y es precisamente esta premisa la base de nuestros servicios profesionales.</p>
      <p>Para nosotros TRABAJAR MÁS no implica necesariamente MEJOR CALIDAD.</p>
      <p>Buscamos optimizar el trabajo de las empresas para que hagan más, en menor tiempo y con la calidad y transparencia necesaria para satisfacer a sus clientes.</p>
    </div>
  </div>
  <div class="row" style="margin-top: 40px; text-align: center;">
    <h4 style="margin-bottom: 20px; width: 100%;">OFRECEMOS SOLUCIONES TECNOLÓGICAS DE DISTINTA ÍNDOLE</h4>
    <div class="row" style="width: 100%;">
      <div class="col">
        <img src="../../../assets/img/icono_01.png" alt="Image" style="width: auto; height: 100px;">
        <p>SISTEMAS WEB<br>PERSONALIZADOS</p>
      </div>
      <div class="col">
        <img src="../../../assets/img/icono_02.png" alt="Image" style="width: auto; height: 100px;">
        <p>LANDING PAGES Y<br>PÁGINAS WEB</p>
      </div>
      <div class="col">
        <img src="../../../assets/img/icono_03.png" alt="Image" style="width: auto; height: 100px;">
        <p>SISTEMAS DE<br>VIGILANCIA POR<br>CÁMARAS</p>
      </div>
    </div>
  </div>
  <div class="row" style="margin-top: 40px;">
    <div class="col-md-7">
      <h4>NUESTRA FILOSOFIA DE TRABAJO</h4>
      <p>El tiempo es oro y lo tenemos muy claro. Nuestra metodología de trabajo es 100% remota. Realizamos nuestras reuniones y conferencias de seguimiento con nuestros Clientes, a través de Plataformas para reuniones en la nube.</p>
      <p>Buscamos no consumir tiempos innecesarios de nuestros clientes y mantenemos una retroalimentación periódica con ellos.</p>
      <p>Nos adaptamos a sus tiempos y estamos siempre disponibles para cualquier consulta y/o guía que necesiten.</p>
      <p>Más que un Proveedor de servicio, queremos ser tu Partner!</p>
    </div>
    <div class="col-md-5" style="margin: 0 auto;">
      <img src="../../../assets/img/diagrama.jpg" alt="Image" style="width: 80%; height: auto; margin-left: 10%;">
    </div>
  </div>
</div>
<app-pie></app-pie>
