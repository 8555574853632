import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './web/home/home.component';
import { ContactoComponent } from './web/contacto/contacto.component';
import { PortafolioComponent } from './web/portafolio/portafolio.component';
import { LoginComponent } from './intranet/login/login.component';
import { DashboardComponent } from './intranet/dashboard/dashboard.component';
import { PaisComponent } from './intranet/dashboard/pais/pais.component';
import { RegionComponent } from './intranet/dashboard/region/region.component';
import { ComunaComponent } from './intranet/dashboard/comuna/comuna.component';
import { EmpresaComponent } from './intranet/dashboard/empresa/empresa.component';
import { PortafolioDComponent } from './intranet/dashboard/portafolio/portafolio.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'contacto', component: ContactoComponent },
  { path: 'portafolio', component: PortafolioComponent },
  { path: 'login', component: LoginComponent },
  { path : 'dashboard', component : DashboardComponent,
    children: [
      { path : 'pais', component : PaisComponent },
      { path : 'region', component : RegionComponent },
      { path : 'comuna', component : ComunaComponent },
      { path : 'empresa', component : EmpresaComponent },
      { path : 'portafolioD', component : PortafolioDComponent },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
