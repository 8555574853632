<p><mat-icon (click)="cerrar()" style="float: right; cursor: pointer;">highlight_off</mat-icon></p>
<h1 mat-dialog-title style="text-align: center;">{{dialog.tit}}</h1>
<div mat-dialog-content>
    <div *ngIf="dialog.acc === 'CP' || dialog.acc === 'EP'">
        <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend" style="width: 30%;">
                <span class="input-group-text" id="cbPais" style="background-color: white; border: none;">Empresa</span>
            </div>
            <select class="custom-select" id="cbPais" style="width: 60%;" [(ngModel)]="proyecto.id_empresa">
                <option value="" selected>Seleccionar</option>
                <option *ngFor="let empresa of empresas" [value]="empresa.id_empresa">{{empresa.nombre}}</option>
            </select>
        </div>
        <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend" style="width: 30%;">
                <span class="input-group-text" id="txtTitulo" style="background-color: white; border: none;">Titulo</span>
            </div>
            <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="txtTitulo" [(ngModel)]="proyecto.titulo">
        </div>
        <div class="input-group input-group-sm mb-3">
            <div class="input-group-prepend" style="width: 30%;">
                <span class="input-group-text" style="background-color: white; border: none;">Descripción</span>
            </div>
            <textarea class="form-control" aria-label="Descripción" [(ngModel)]="proyecto.descripcion"></textarea>
        </div>
    </div>
    <div *ngIf="dialog.acc === 'CIP'">
        <div style="text-align: center;"><input type="file" style="margin-bottom: 20px;" (change)="cargandoImagen($event.target.files, dialog.idP)" multiple></div>
        <div *ngFor="let imagen of imagenes" class="row" style="margin: 0 auto; margin-bottom: 40px;">
            <div class="col-sm" *ngIf="imagen.imagen1 !== ''">
                <img style="width: 100%; height: 100%;" class="d-block" src="{{imagen.imagen1}}">
                <div style="width: 100%; text-align: center;">
                    <mat-icon title="Eliminar" style="cursor: pointer;" (click)="borrar(imagen.idImg1)">delete</mat-icon>
                </div>
            </div>
            <div class="col-sm" *ngIf="imagen.imagen1 === ''">&nbsp;</div>
            <div class="col-sm" *ngIf="imagen.imagen2 !== ''">
                <img style="width: 100%; height: 100%;" class="d-block" src="{{imagen.imagen2}}">
                <div style="width: 100%; text-align: center;">
                    <mat-icon title="Eliminar" style="cursor: pointer;" (click)="borrar(imagen.idImg2)">delete</mat-icon>
                </div>
            </div>
            <div class="col-sm" *ngIf="imagen.imagen2 === ''">&nbsp;</div>
            <div class="col-sm" *ngIf="imagen.imagen3 !== ''">
                <img style="width: 100%; height: 100%;" class="d-block" src="{{imagen.imagen3}}">
                <div style="width: 100%; text-align: center;">
                    <mat-icon title="Eliminar" style="cursor: pointer;" (click)="borrar(imagen.idImg3)">delete</mat-icon>
                </div>
            </div>
            <div class="col-sm" *ngIf="imagen.imagen3 === ''">&nbsp;</div>
            <div class="col-sm" *ngIf="imagen.imagen4 !== ''">
                <img style="width: 100%; height: 100%;" class="d-block" src="{{imagen.imagen4}}">
                <div style="width: 100%; text-align: center;">
                    <mat-icon title="Eliminar" style="cursor: pointer;" (click)="borrar(imagen.idImg4)">delete</mat-icon>
                </div>
            </div>
            <div class="col-sm" *ngIf="imagen.imagen4 === ''">&nbsp;</div>
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <button *ngIf="dialog.acc === 'CP' || dialog.acc === 'EP'" mat-button style="background-color: #D4691F; color: white; margin: 0 auto;" (click)="accion()">{{dialog.btn}}</button>
</div>
